import * as React from "react";
import { graphql } from "gatsby";
import { motion } from "framer-motion";

import { NavContext } from "../components/nav";

import * as musicStyles from "./__styles/music.module.scss";
import { Helmet } from "react-helmet";
import MusicPlayer from "../components/music";

const MusicPage = ({ data }) => {
  const navContext = React.useContext(NavContext);

  React.useEffect(() => {
    navContext.setNav([{ name: "Music", to: "/music" }]);
  }, []);

  return (
    <>
      <Helmet>
        <title>Quentin Golsteyn | Music</title>
      </Helmet>
      <motion.div
        className="section--narrow content"
        initial={{ opacity: 0 }}
        variants={{
          enter: { opacity: 1, transition: { duration: 0.3, delay: 0.2 } },
          exit: { opacity: 0, transition: { duration: 0.2 } },
        }}
      >
        <h1 className="centered">Music</h1>
        <p>
          In 2020, I started playing around in Garageband, learning how to make
          music with an eventual goal to make an OST for a video game.
        </p>
        <p>
          What you will find below is nothing professional, just a small record
          of what I have been able to experiment with over the last year and a
          half.
        </p>
        {data.allMusicJson.nodes.map(({ name, path }) => (
          <figure>
            <div className={musicStyles.songTitle}>{name}</div>
            <MusicPlayer src={path} />
          </figure>
        ))}
      </motion.div>
    </>
  );
};

export const query = graphql`
  {
    allMusicJson(sort: { fields: date, order: DESC }) {
      nodes {
        name
        date
        path
      }
    }
  }
`;

export default MusicPage;
