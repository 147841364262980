import React from "react";
import { Howl } from "howler";
import { Play, Pause, Loader } from "react-feather";

import "./index.scss";

const SLIDER_BUTTON_RADIUS = 24;

const formatTimestamp = (timestamp) =>
  `${String(Math.floor(timestamp / 60)).padStart(2, "0")}:${String(
    timestamp % 60
  ).padStart(2, "0")}`;

const MusicPlayer = ({ src }) => {
  const musicPlayerRef = React.useRef();

  const musicStateRef = React.useRef({
    sliderPosition: 0,
    isSliding: false,
    sound: null,
  });

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSliding, setIsSliding] = React.useState(false);
  const [sliderPosition, setSliderPosition] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [seekTimestamp, setSeekTimestamp] = React.useState(0);
  const [durationTimestamp, setDurationTimestamp] = React.useState(0);
  const [playerWidth, setPlayerWidth] = React.useState(0);

  const calculateNewSliderPosition = (e) => {
    const xStart =
      musicPlayerRef.current?.offsetLeft + SLIDER_BUTTON_RADIUS / 2;
    const width = musicPlayerRef.current?.offsetWidth - SLIDER_BUTTON_RADIUS;
    const mouseX = e.clientX;

    const newSliderPosition = Math.max(
      Math.min((mouseX - xStart) / width, 1),
      0
    );
    setSliderPosition(Math.max(Math.min((mouseX - xStart) / width, 1), 0));
    musicStateRef.current.sliderPosition = newSliderPosition;
  };

  React.useEffect(() => {
    const element = musicPlayerRef.current;

    setPlayerWidth(element.offsetWidth);
    const observer = new ResizeObserver(() => {
      setPlayerWidth(element.offsetWidth);
    });

    observer.observe(element);

    return () => observer.unobserve(element);
  }, []);

  React.useEffect(() => {
    musicStateRef.current = {
      ...musicStateRef.current,
      isSliding,
    };
    if (isSliding) {
      const listener = (e) => {
        e.preventDefault();
        e.stopPropagation();

        calculateNewSliderPosition(e);
      };

      document.addEventListener("pointermove", listener);

      return () => document.removeEventListener("pointermove", listener);
    }
  }, [isSliding]);

  React.useEffect(() => {
    const seekProgressFunction = () => {
      if (musicStateRef.current.sound !== null) {
        if (!musicStateRef.current.isSliding) {
          setSliderPosition(sound.seek() / sound.duration());
        }
        setSeekTimestamp(Math.floor(sound.seek()));
        requestAnimationFrame(seekProgressFunction);
      }
    };

    const sound = new Howl({
      src,
    });

    musicStateRef.current.sound = sound;

    sound.on("load", () => {
      setIsLoading(false);
      setDurationTimestamp(Math.ceil(sound.duration()));
    });

    sound.on("play", () => {
      setIsPlaying(true);
      requestAnimationFrame(seekProgressFunction);
    });

    sound.on("pause", () => {
      setIsPlaying(false);
    });

    sound.on("stop", () => {
      setIsPlaying(false);
    });

    sound.on("end", () => {
      setIsPlaying(false);
    });

    return () => {
      musicStateRef.current.sound = null;
      sound.unload();
    };
  }, [src]);

  return (
    <div
      className="musicPlayer"
      aria-label="Audio Player"
      role="region"
      tabIndex="0"
      onKeyDown={(e) => {
        switch (e.key) {
          case " ": {
            e.preventDefault();
            e.stopPropagation();
            if (isPlaying) {
              musicStateRef.current.sound.pause();
            } else {
              musicStateRef.current.sound.play();
            }
            break;
          }
          case "ArrowRight": {
            e.preventDefault();
            e.stopPropagation();
            musicStateRef.current.sound.seek(
              musicStateRef.current.sound.seek() + 1
            );
            break;
          }
          case "ArrowDown": {
            e.preventDefault();
            e.stopPropagation();
            musicStateRef.current.sound.seek(
              musicStateRef.current.sound.seek() - 10
            );
            break;
          }
          case "ArrowLeft": {
            e.preventDefault();
            e.stopPropagation();
            musicStateRef.current.sound.seek(
              musicStateRef.current.sound.seek() - 1
            );
            break;
          }
          case "ArrowUp": {
            e.preventDefault();
            e.stopPropagation();
            musicStateRef.current.sound.seek(
              musicStateRef.current.sound.seek() + 10
            );
            break;
          }
        }
      }}
    >
      <button
        title={isPlaying ? "Pause" : "Play"}
        aria-label={isPlaying ? "Pause" : "Play"}
        onClick={() => {
          if (isPlaying) {
            musicStateRef.current.sound.pause();
          } else {
            musicStateRef.current.sound.play();
          }
        }}
      >
        {isLoading ? (
          <Loader fill="currentColor" size={18} />
        ) : isPlaying ? (
          <Pause fill="currentColor" size={18} />
        ) : (
          <Play fill="currentColor" size={18} />
        )}
      </button>
      <div
        className="musicBar"
        tabIndex="0"
        aria-valuetext="seek audio bar"
        aria-valuemax="100"
        aria-valuemin="0"
        aria-valuenow={Math.round(sliderPosition * 100)}
        role="slider"
        ref={musicPlayerRef}
        onMouseDown={(e) => {
          if (e.button === 0) {
            setIsSliding(true);

            calculateNewSliderPosition(e);

            const listener = () => {
              setIsSliding(false);
              musicStateRef.current.sound.seek(
                musicStateRef.current.sliderPosition *
                  musicStateRef.current.sound.duration()
              );
              document.removeEventListener("mouseup", listener);
            };

            document.addEventListener("mouseup", listener);
          }
        }}
      >
        <div
          className={isSliding ? "sliderButton sliding" : "sliderButton"}
          style={{
            width: SLIDER_BUTTON_RADIUS,
            height: SLIDER_BUTTON_RADIUS,
            marginLeft: `${sliderPosition * (playerWidth - 24)}px`,
          }}
        ></div>
        <div className="bar"></div>
        <div className="time start">{formatTimestamp(seekTimestamp)}</div>
        <div className="time end"> {formatTimestamp(durationTimestamp)}</div>
      </div>
    </div>
  );
};

export default MusicPlayer;
